export const CHANNEL_COLOR = {
  SMS: "#0091ea",
  WHATSAPP: "#25d366",
  MESSENGER: "#0078ff",
  CALL: "#1a237e",
};

export const DEFAULT_CHART_FONT = {
  fontSize: 12,
  fontFamily: "Poppins",
  fontStyle: '500'
};

export const DEFAULT_TOOLTIP_CHART_FONT = {
  titleFontFamily: DEFAULT_CHART_FONT.fontFamily,
  titleFontSize: DEFAULT_CHART_FONT.fontSize,
  titleFontStyle: DEFAULT_CHART_FONT.fontStyle,
  bodyFontFamily: DEFAULT_CHART_FONT.fontFamily,
  bodyFontSize: DEFAULT_CHART_FONT.fontSize,
  bodyFontStyle: DEFAULT_CHART_FONT.fontStyle,
}