<template>
  <div>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="rounded-lg">
          <v-card-text class="py-1">
            <v-row align="center">
              <v-col cols="12" md="5">
                <v-list two-line subheader>
                  <v-list-item>
                    <v-list-item-action class="mr-2">
                      <v-icon>mdi-clock-outline</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>
                        <span
                          class="grey--text text--darken-3 font-weight-bold"
                          >{{ $t("statistics.date.title") }}</span
                        >
                      </v-list-item-title>
                      <v-list-item-subtitle>{{
                        $t("statistics.date.description")
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-col cols="12" md="7">
                <div class="px-0 d-flex flex-wrap justify-end align-center">
                  <slot></slot>
                  <div :class="{ 'mb-2': $vuetify.breakpoint.smAndDown }" class="ml-2">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="secondary"
                          @click="changeDates()"
                          :disabled="loading"
                          v-on="on"
                          v-bind="attrs"
                        >
                          {{ dateFormatted }}
                        </v-btn>
                      </template>
                      <span>{{ $t("statistics.btn.date.description") }}</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- datepicker dialog -->
    <v-dialog v-model="dialogDatepicker" persistent width="290px">
      <v-date-picker
        range
        header-color="secondary"
        v-model="datePicker"
        color="secondary"
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dialogDatepicker = false">{{
          $t("btn.cancel")
        }}</v-btn>
        <v-btn
          text
          :disabled="!isRangeDates"
          color="primary"
          @click="updateDates()"
        >
          {{ $t("btn.ok") }}
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  created() {
    this.initDates();
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    dialogDatepicker: false,
    dates: [],
    datePicker: [],
    format: "YYYY-MM-DD",
  }),
  methods: {
    initDates() {
      const date = this.$moment();
      const end = date.format(this.format);
      const start = date.subtract(1, "months").format(this.format);
      this.dates = [start, end];
    },
    changeDates() {
      this.dialogDatepicker = true;
    },
    updateDates() {
      const dates = this.$utils.parseRangeDates(this.datePicker);
      this.dates = dates;
      this.$emit("changed", this.dates);
      this.dialogDatepicker = false;
    },
    getDates() {
      return this.dates;
    }
  },
  computed: {
    dateFormatted() {
      let formatted;
      const start = this.dates[0];
      const end = this.dates[1];

      if (start === end) {
        return start;
      }

      formatted = start + " - " + end;

      return formatted;
    },
    isRangeDates() {
      return this.datePicker.length > 1;
    },
  },
};
</script>